import axios from "axios";
import React, { createContext, useContext } from "react";

//API
//export const API = "http://localhost:5256/api/";
//export const API = "https://admin.freebus.es/api/";
export const API = "https://freebus.autobusing.com/api/";
export const BASE = "https://freebus.autobusing.com/";
//export const BASE = "https://admin.freebus.es/";
//export const APIKEY = "AIzaSyAWWG2kuECdk7EYKxFfBWnu93zLoyalEiM";
export const APIKEY = "AIzaSyBFcwzlBP3RlcgQslDC1Q5djx3189mJYZI";

//Util
export function secu_gitaner(data) {
  for (var i = 0; i < 12; i++) {
    data = btoa(data);
  }
  return data;
}

//Context
export const AppContext = createContext({});
export const UseAppContext = () => useContext(AppContext);

//Consume API
axios.interceptors.response.use(function (response) {
  if (
    response.data.exito === false &&
    response.data.error === "Aut. Error" &&
    response.config.url.includes("api/check") === false
  ) {
    window.location.reload();
  }
  return response;
});
export const apic = axios;

//DOM
export const createCustomClearButton = (onClick) => {
  return (
    <button className="btn btn-primary btn-sm" onClick={onClick}>
      <i className="fa fa-times"></i>
    </button>
  );
};

//Window
export const isMobile = () => {
  return window.screen.width < 769 ? true : false;
};
